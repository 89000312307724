import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { global } from "../stitches/stitches.config";
import {
  heading100,
  heading200,
  heading300,
  heading400,
  heading500,
  paragraphTextMedium,
  paragraphTextSmall,
} from "../stitches/combinedStyles";
import "modern-normalize";
import Header from "./shared/Header/Header";
import Footer from "./shared/Footer/Footer";
import CookieConsent from "./shared/CookieConsent";
// import LinkedInTag from 'react-linkedin-insight';

import favicon32 from "../images/favicons/favicon-32x32.png";
import favicon16 from "../images/favicons/favicon-16x16.png";
import faviconTouch from "../images/favicons/apple-touch-icon.png";

const siteUrl = `https://plastometrex.com`;

type Props = {
  pageDescription?: string;
  backgroundColorUnderneath: "white" | "blue" | "dark";
  backgroundColor?: string;
  seo: {
    title?: string;
    description?: string;
  };
  children?: ReactNode;
};

const Layout = ({
  pageDescription,
  backgroundColorUnderneath,
  backgroundColor,
  seo,
  children,
}: Props) => {
  // Import global styles
  globalStyles();
  const location = useLocation();
  //console.log(location.pathname);

  const fullTitle = seo.title ? `${seo.title} | Plastometrex` : "Plastometrex";
  const pageDesc =
    pageDescription ||
    "Profilometry-based Indentation Plastometry (PIP) is a revolutionary new approach to the mechanical testing of metals.";

    //LinkedInTag.init("5929634", 'dc');
  
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title key="title">{fullTitle}</title>
        <meta key="description" name="description" content={pageDesc} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta property="og:locale" content={"en_gb"} />
        <meta property="og:site_name" content="Plastometrex" />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={pageDesc} />
        <meta property="og:image" content={`${siteUrl}/shareImage.png`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:url" content={`${siteUrl}${location.pathname}`} />
        <meta name="twitter:description" content={pageDesc} />
        <meta name="twitter:image" content={`${siteUrl}/shareImage.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={faviconTouch} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        {backgroundColor && (
          <style type="text/css">
            {` html {
            background-color: ${backgroundColor} !important;
          }`}
          </style>
        )}
      </Helmet>
      <Header backgroundColorUnderneath={backgroundColorUnderneath} />
      {children}
      <Footer />
      <CookieConsent />
      <script src="https://player.vimeo.com/api/player.js"></script>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        crossOrigin=""
        src="//js-na1.hs-scripts.com/6034553.js"
      ></script>
    </>
  );
};

export default Layout;

const globalStyles = global({
  html: { backgroundColor: "$offwhite" },
  h1: { ...heading100 },
  h2: { ...heading200 },
  h3: { ...heading300 },
  h4: { ...heading400 },
  h5: { ...heading500 },
  p: { ...paragraphTextMedium },
  span: { ...paragraphTextSmall },
  a: {
    color: "$blue100",
    textDecoration: "none",
  },
  "@font-face": [
    {
      fontFamily: "Inter",
      src: `url("/assets/fonts/Inter/Inter-Regular.ttf") format('truetype')`,
      fontWeight: 400,
    },
    {
      fontFamily: "Inter",
      src: `url("/assets/fonts/Inter/Inter-Medium.ttf") format('truetype')`,
      fontWeight: 500,
    },
    {
      fontFamily: "Inter",
      src: `url("/assets/fonts/Inter/Inter-SemiBold.ttf") format('truetype')`,
      fontWeight: 600,
    },
    {
      fontFamily: "Inter",
      src: `url("/assets/fonts/Inter/Inter-Bold.ttf") format('truetype')`,
      fontWeight: 700,
    },
    {
      fontFamily: "Open Sans",
      src: `url("/assets/fonts/OpenSans/OpenSans-Regular.ttf") format('truetype')`,
      fontWeight: 400,
    },
    {
      fontFamily: "Open Sans",
      src: `url("/assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format('truetype')`,
      fontWeight: 600,
    },
    {
      fontFamily: "Open Sans",
      src: `url("/assets/fonts/OpenSans/OpenSans-Bold.ttf") format('truetype')`,
      fontWeight: 700,
    },
  ],
});
